<template>
  <div class="card card-body shadow-sm">
    <app-modal-confirm
      ref="modal_show_confirm"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteInvoiceReceipt()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.INVOICE_RECEIPT_LIST"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >

        <template v-slot:body-cell-apartment_id="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.apartment_name" style="color: #007bff;padding-top: 9px;" @click="handlerApartmentNameClick(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
              {{props.row.apartment_name}}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-url="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.url" style="color: #007bff;padding-top: 9px;" @click="handlerUrlInvoiceReceiptClick(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
              {{props.row.url_name}}
            </p>
          </td>
        </template>

        <template v-slot:table-menu-right>
          <button @click="handleBtnCreateClick()" class="btn btn-warning">
            {{ $t("common.create") }}
          </button>
        </template>

        <template v-slot:body-cell-file_type="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.file_type" class="app-table-p app-cell-tooltip mb-0" style="padding-top: 9px;">
              {{ getFileType(props.row) }}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-visible="props">
          <td class="app-align-middle">
            <div class="form-group">
              <div class="custom-control custom-switch text-center"  style="padding-top: 15px;">
                <input v-model="props.row.visible" type="checkbox" class="custom-control-input" :id="'customSwitch'+props.row.id" @click="changeVisible(props.row.id, $event)">
                <label class="custom-control-label" :for="'customSwitch'+props.row.id"></label>
              </div>
            </div>
          </td>
        </template>

        <template v-slot:body-cell-delete="props">
          <td class="app-align-middle text-center app-p-none">
            <button
              @click="handleBtnDeleteConfirm(props.row)"
              class="btn btn-danger"
            >
              {{ $t("common.delete") }}
            </button>
          </td>
        </template>

      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
export default {
  name: "InformationList",
  components: {
    "app-basic-table": AppBasicTable,
    AppDateRangePicker,
  },
  data() {
    return {
      idCache: null,
      invoiceId: null,
      paramGetList: {},
      filters: {
      },
      columns: [
        {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
        {name: "visible", label: '表示'},
        {name: "apartment_id", label: this.$t("common.mansion"), sortable: true},
        {name: "customer_name", label: this.$t("reservation.customer"), sortable: true},
        {name: "month", label: this.$t("common.created at"), sortable: true, textAlign: 'text-center'},
        {name: "file_type", label: this.$t("invoice_receipt.type"), sortable: true},
        {name: "url", label: 'リンク'},
        {name: "delete", label: this.$t("common.delete"), textAlign: 'text-center'},
      ],
      meta: {
        apartments: [],
      },
      endPoint: ''
    };
  },
  watch : {
  },
  methods: {
    handleBtnCreateClick() {
      this.$router.push({name: this.ROUTES.ADMIN.INVOICE_RECEIPT})
    },
    handleBtnDeleteConfirm(entry) {
        this.invoiceId = entry.id;
        this.$refs.modal_show_confirm.show();
    },
    async deleteInvoiceReceipt(){
      const res = await this.$request.delete(this.ENDPOINT.INVOICE_RECEIPT_DELETE(this.invoiceId));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        this.$refs.modal_show_confirm.hide();
        await this.redirectDefaultValue();
        this.$router.go(0);
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      }
    },
    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.INVOICE_RECEIPT_LIST,
        query : {
        }
      }).catch(e => {
        console.log('no warning console');
      })
    },
    handlerApartmentNameClick(entry) {
      let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
      window.open(routeApartment.href, '_blank');
    },
    handlerUrlInvoiceReceiptClick(entry) {
      window.open(entry.url, '_blank');
    },
    onResetAllSearch() {
      this.$router.push({name: this.$route.name,
      })
    },
    onSearch() {
      // this.getTourSelectName();
    },
    getFileType(row) {
      if (row.file_type == 0) return '請求書';
      if (row.file_type == 1) return '領収書';
    },
    async changeVisible(id, event)
    {
      let visible = event.target.checked === false ? 0 : 1;
      const res = await this.$request.patch(this.ENDPOINT.INVOICE_RECEIPT_EDIT(id), {'visible': visible});
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg update ok"));
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      }
    }
  },
  mounted: function () {
  },
}
</script>
<style lang="scss" scoped>
  tr * {
    height: 40px;
  }
</style>
